/* Apply px-2 for small screens */
.catalogue {
  padding-left: 0.5rem; /* 2 * 0.25rem */
  padding-right: 0.5rem;
}

/* For medium screens and above (md:px-12) */
@media (min-width: 768px) {
  .catalogue {
    padding-left: 3rem; /* 12 * 0.25rem */
    padding-right: 3rem;
  }
}

/* For extra-large screens and above (xl:px-64) */
@media (min-width: 1280px) {
  .catalogue {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
}
