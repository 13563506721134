.hide-on-larger-than-sm {
  display: none;
}

@media (min-width: 640px) {
  .hide-on-larger-than-sm {
    display: block;
  }
}

.menu-container {
  display: none;
}

@media (min-width: 768px) {
  .menu-container {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-right: 16px;
  }
}

/* Default styles for both navbars */
.navbar-1,
.navbar-2 {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  transition: all 0.3s;
}

/* Navbar 1 visibility rules */
.navbar-1 {
  display: block;
}

/* Hide Navbar 1 on desktop when on dashboard/home */
@media screen and (min-width: 640px) {
  .navbar-1.dashboard-home {
    display: none;
  }
}

/* Add or update these styles in your Navbar.css file */

.navbar-2 {
  display: none;
  position: fixed;
  top: 0;
  left: 0; /* Changed from right to left */
  height: 100vh; /* Full height */
  width: 150px; /* Fixed width for the sidebar */
  background: linear-gradient(
    to bottom,
    #5345e6,
    #613ab1
  ); /* Adjusted gradient direction */
  z-index: 50;
  transition: all 0.3s;
}

/* Show Navbar 2 only on desktop and only when on dashboard/home */
@media screen and (min-width: 768px) {
  .navbar-2.dashboard-home {
    display: block;
  }
}

/* Styles for the content inside navbar-2 */
.navbar-2 .nav-content {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  height: 100%;
}

.navbar-2 .logo-container {
  margin-bottom: 3rem;
}

.navbar-2 .nav-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.navbar-2 h1 {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 0.3s;
}

.navbar-2 h1:hover {
  background: rgba(255, 255, 255, 0.1);
}

.studio-navbar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .studio-navbar {
    display: flex;
  }

  .top-navbar {
    left: 150px;
  }
}

@media screen and (max-width: 640px) {
  .nav-container {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .nav-container {
    display: flex;
    align-items: center;
    margin-left: 1.5rem; /* equivalent to ml-6 */
    gap: 1.5rem; /* equivalent to space-x-4 */
  }
}

@media screen and (min-width: 768px) {
  #sidebar {
    display: block;
  }
}
