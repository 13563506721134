@media (min-width: 0px) and (max-width: 768px) {
  .wrapper {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .main_description {
    width: 100%;
  }

  .navbar-brand img {
    width: 50%;
  }
  .main_heading {
    font-size: 25px;
    color: var(--mainheading-text-color);
    font-weight: 600;
  }
  .main_description {
    font-size: 14px;
  }
  .responsive_div {
    display: none !important;
  }
  .loginpage-heading {
    font-size: 24px;
  }
  .dashboard_card .card_title {
    font-size: 18px;
  }
  .navbar-expand-md {
    background-color: rgb(3, 41, 68, 1) !important;
  }
  .animation_img {
    height: auto;
  }

  .login_button {
    margin-bottom: 20px;
  }
  .banner_section_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 50px;
    color: #ffffff;
    padding-bottom: 10px;
  }
  .logistics_img {
    width: 100%;
  }
}

@media (min-width: 769px) and(max-width:1023) {
  .banner_section_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 54px !important;
    line-height: 76px;
    color: #ffffff;
    padding-bottom: 40px;
  }
}

@media (min-width: 769px) and (max-width: 980px) {
  .banner_section_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 62px;
    line-height: 76px;
    color: #ffffff;
    padding-bottom: 40px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .banner_buttons {
    flex-direction: column;
    display: flex;
  }

  .start_button {
    margin-top: 10px;
    margin-left: unset !important;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 90.4285714286% !important;
  }
}

#animated_text {
  animation: colorChange 2s infinite;
}

.blue_text {
  color: rgb(3, 77, 130);
}
.red_text {
  color: #e9202a;
}

.banner_sub_heading {
  color: rgb(3, 77, 130);
  font-size: 30px;
  font-style: normal;
}
.footer_icons img {
  height: 24px;
  width: 24px;
}
.footer_links a {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  text-decoration: underline !important;
}
.footer_paragraph {
  font-size: 16px;
  line-height: 24px;
}

.banner_bg {
  position: absolute;
  right: 0px;
  bottom: 150px;
  width: 80%;
}

.dropdown-menu {
  margin-top: 0;
}

.dropdown_menu a {
  font-size: 14px;
  color: #000;
  font-style: normal;
  font-weight: 500;
}

.dropdown-item p {
  display: none;
  margin-top: 5px;
}

.dashboard_card.card .card-footer {
  background-color: unset;
  border: none;
  padding-bottom: 15px;
}

.dashboard_card.card {
  border-radius: 16px;
  color: #3c4c57;
  border: none;
  font-size: large;
  font-weight: 500;
}

.dashboard_card h5 {
  font-size: 14px;
  font-size: #000;
  font-style: normal;
}
.dashboard_card p {
  font-size: 16px;
  color: #676767;
  font-style: normal;
  font-weight: 500;
}
.count_text {
  font-size: 14px;
  color: #464444;
}

.gradient-bg1 {
  background-image: linear-gradient(
    205deg,
    hsl(240deg 100% 20%) 0%,
    hsl(328deg 100% 35%) 45%,
    hsl(55deg 100% 50%) 100%
  );

  background-size: 600%;
}

.inputfield_img {
  width: 23px;
}
.inputfield.form-control {
  background-color: #eee;
  color: #636363 !important;
  font-weight: 500 !important;
  height: 48px;
}
.inputfield.form-control {
  font-size: 14px !important;
  font-weight: 500px !important;
}
.form-control,
.form-select,
textarea {
  font-size: 14px !important;
  font-weight: 500px !important;
}

.card_paragraph {
  font-size: 18px;
  color: #000;
}
.card_heading {
  color: rgb(3, 77, 130);
  font-weight: 600;
  font-size: 30px;
  font-style: normal;
}
.card_img {
  height: 80px;
  width: 80px;
  background: antiquewhite;
  padding: 8px;
  border-radius: 50%;
}
.card_title {
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
  color: #464444;
}

.tabs_card.card {
  background-color: #fff;
  border-radius: 0px 70px 0px 70px;
  border-bottom: 4px solid #28bdd7;
  border-right: 4px solid #28bdd7;
  border-top: none;
  border-left: none;
}

.tabs_right_card.card {
  background-color: #28bdd7;
  border-radius: 70px 0px 70px 0px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-item img {
  vertical-align: middle;
  margin-right: 5px;
  transition: filter 0.3s;
}

.dropdown_menu h6 {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  margin-top: 10px;
}

a span {
  display: none;
}

.dropdown-item:hover span {
  display: block;
  color: #fff;
  font-size: 12px;
  margin-left: 15px;
}

@media (min-width: 991px) {
  .dropdown-carrot {
    top: -9px;
    left: 10px;
  }
}

.breadcrumb-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  letter-spacing: 0.1px;
  color: #034d82;
}
.bannerimage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.bannerimage img {
  width: 100%;
  float: none;
  height: 450px;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  object-fit: contain;
}

.dropdown-item img {
  margin-right: 10px;
}

.card {
  overflow: hidden;
}

.card-body,
.card-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.preview1 {
  background-size: contain;
  background-repeat: no-repeat;
}

.model-generation-container {
  @apply p-4 bg-gray-100 rounded-lg;
}

.model-generation-container .grid {
  @apply gap-6;
}

@screen md {
  .model-generation-container .grid {
    @apply gap-4;
  }
}

.model-generation-container input[type="text"]:focus {
  @apply ring-2 ring-blue-400 border-transparent;
}
