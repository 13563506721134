.nexusware-loader-container {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nexusware-loader-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #febc0a;
  border-right-color: #e74539;
  border-bottom-color: #613ab1;
  border-left-color: #4483f5;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.custom-button {
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 12px;
  border-color: #febc0a;
  font-size: 14px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.background-gradient {
  background: linear-gradient(
    131.51deg,
    rgba(254, 188, 10, 0.1) 1.04%,
    rgba(68, 131, 245, 0.1) 25.29%,
    rgba(235, 65, 53, 0.1) 50.19%,
    rgba(102, 56, 184, 0.1) 74.77%,
    rgba(254, 188, 10, 0.1) 100%
  );
  width: 100%;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #3b82f6;
}

.toggle-checkbox:checked + .toggle-label::before {
  transform: translateX(100%);
}

.toggle-checkbox:checked {
  right: 0;
  border-color: transparent;
}

.hover-effect {
  position: relative;
  width: 10rem;
  height: 10rem;
}

.hover-effect .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hover-effect:hover .overlay {
  opacity: 1;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 393px;
  height: auto;
  margin: 0 auto;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(249, 249, 249, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
}

.image-container:hover .image-overlay {
  opacity: 1;
}
