@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");
* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #222226;
}

:root {
  --background-color: #222226;
  --headerbg-color: rgb(3, 41, 68, 1);
  --mainheading-text-color: #000;
  --text-color: #000;
  --button-background: #ccc;
  --button-text: #444;
  --button-border: rgba(0, 0, 0, 0.03);
  --button-hover: #bbb;
  --color1: rgba(255, 220, 201, 0.23);
  --color2: rgba(232, 223, 255, 0.4);
  --color3: rgba(188, 223, 231, 0.23);
  --color4: rgba(255, 239, 197, 0.4);
  --card-bg: #fff;
  --settings-heading: #181b36;
  --text-color: #000;
}

/* Dark Mode Overrides */
.dark-mode {
  --background-color: #1e1e1e;
  --headerbg-color: rgba(36, 43, 51, 0.23);
  --text-color: #ffffffba;
  --button-background: #444;
  --button-text: #fff;
  --button-border: rgba(255, 255, 255, 0.05);
  --button-hover: #333;
  --color1: rgba(36, 43, 51, 0.23);
  --color2: rgba(36, 43, 51, 0.23);
  --color3: rgba(36, 43, 51, 0.23);
  --color4: rgba(36, 43, 51, 0.23);
  --card-bg: rgba(36, 43, 51, 0.23);
  --text-color: #ffffffba;
  --mainheading-text-color: #fff;
  --settings-heading: #fff;
}

/* .login {
  background-color: var(--card-bg);
} */
body {
  background-color: var(--background-color);
  color: var(--text-color);
}
.login {
  background-color: var(--background-color);
}

.loginpage-heading {
  font-size: 34px;
  line-height: 32px;
  font-weight: 500;
  color: var(--mainheading-text-color);
  text-transform: capitalize;
}

.loginpage-heading p {
  font-size: 14px;
  font-style: normal;
  color: var(--text-color);
  font-weight: 500;
}
.pass-link a {
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
}
.navbar {
  background: var(--headerbg-color);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
}

.footer_center_logo {
  background: var(--headerbg-color);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
}

.main_heading {
  font-size: 32px;
  color: var(--mainheading-text-color);
  font-weight: 600;
}
.form-control {
  background-color: unset !important;
}

/* .header_menu a.active {
  color: #00edc1 !important;
} */
.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
  color: var(--text-color) !important;
}
.navbar-toggler {
  padding: 0px;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.navbar-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
    transparent,
    rgba(0, 0, 0, 0.2) 67%,
    rgba(0, 0, 0, 0.7)
  );
  background: url("https://html.designingmedia.com/artelligence/assets/images/banner-background.png")
    repeat fixed 100%;
  background-size: cover;
}

.bg-whites.scrolled {
  background-color: rgb(3, 41, 68, 1);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.navbar-light .navbar-toggler {
  border: unset !important;
}

.logo {
  display: flex;
  justify-content: center;
}

.navbar .nav-link {
  color: #fff !important;
}

.navbar .nav-link:hover {
  color: #00edc1;
}

.breadcrumb-item a {
  color: #613ab1 !important;
}

.nav-link {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
}

table {
  margin: 0px;
}
.table td {
  font-size: 14px;
}
.item {
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
}
.item img {
  height: 20px;
  width: 20px;
}

.values-container {
  text-align: center;
}

.values {
  margin: 0;
}

.banner_right_image {
  width: 100%;
  height: auto;
}

#preloader-background,
#circle-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit- flex;
  display: -ms- flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader-heading {
  margin: 0 auto;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: center center no-repeat rgba(0, 0, 0, 0.7);
}

#circle-loader {
  width: 120px;
  height: 120px;
  border-style: solid;
  border-width: 5px;
  border-top-color: #28bdd7;
  border-right-color: #28bdd7;
  border-bottom-color: #e95950;
  border-left-color: #e95950;
  border-radius: 100%;
  -webkit-animation: spinLoader 2s linear infinite;
  animation: spinLoader 2s linear infinite;
}

@-webkit-keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#centre-logo {
  position: fixed;
  width: 50px;
  height: 50px;
}

.animatePageIn {
  position: relative;
  -webkit-animation-name: animatePageIn;
  -webkit-animation-duration: 3s;
  animation-name: animatePageIn;
  animation-duration: 3s;
}

@-webkit-keyframes animatePageIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animatePageIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  height: 50px;
  text-transform: capitalize;
  background-color: rgba(254, 188, 10, 0.78);
  background-size: 125% 100%;
  background-position: 0;
  transition: background-position 0.5s;
  border-radius: 12px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: var(--text-color);
}

.outline_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  height: 50px;
  text-transform: capitalize;
  background-color: transparent;
  background-size: 125% 100%;
  background-position: 0;
  transition: background-position 0.5s;
  border-radius: 12px;
  border: 1px solid gray;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: var(--text-color);
}

.navbar-section {
  position: relative;
}

a {
  text-decoration: none;
  text-decoration: unset !important;
}

.list_items_list {
  padding: 0px !important;
}

ul li {
  list-style-type: none;
}

ul.app-abtus-list li {
  width: 100%;
  margin: 5px 0;
  display: flex;
  align-items: flex-start;
}

ul li span {
  width: calc(100% - 30px);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: var(--text-color);
}

.copyright_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.logo img {
  height: 60px;
  object-fit: contain;
  max-width: 150px;
}

.logo {
  display: flex;
  justify-content: center;
}

#animated_text {
  text-shadow: 5px 5px #ff0000;
}

.animation_img {
  height: 72vh;
}

.text {
  position: absolute;
  color: white;
  background-color: black;
  padding: 3px;
  user-select: none;
}

.logistics_img {
  width: 85%;
}

.vertical-center {
  min-height: 100vh;
  background: url(../images/loginpage.svg) 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control.input_field {
  color: var(--text-color) !important;
  border-radius: 12px;
  height: 50px;
}

.login_form {
  background-color: #03294475;
  padding: 20px;
  border-radius: 15px;
}

.login_form h5 {
  font-size: 32px;
  color: #fff;
}

.card {
  /* border-radius: 10px; */
  color: #3c4c57;
  font-weight: 500;
  position: relative;
}
.dashboard_card .card_title {
  color: var(--mainheading-text-color);
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.btn_upload {
  cursor: pointer;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 30%;
  overflow: hidden;
  position: relative;
  color: #fff;
  background-color: #032944;
  border: 1px solid #032944;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.preview1 {
  width: 100%;
  height: 250px;
  border-radius: 12px;
  object-fit: inherit;
}

.hidden {
  display: none;
}

#myDIV {
  display: none;
}

select {
  height: 36px;
  border-radius: 10px;
}
.dropdown-item .dashboard_dropdown:focus,
.dropdown-item .dashboard_dropdown:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: #032944 !important;
}

::selection {
  background: #1a75ff;
  color: #fff;
}
.wrapper {
  overflow: hidden;
  background: #03294475;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}
.wrapper .title-text {
  display: flex;
  width: 200%;
}
.wrapper .title {
  width: 50%;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 40px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 8px;
}
.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner form .field input:focus {
  border-color: #1a75ff;
}
.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder {
  color: #1a75ff;
}

form .btn {
  width: 100%;
  padding: 0px;
}
form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  border: none !important;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #003366,
    #004080,
    #0059b3,
    #0073e6
  );
  border-radius: 15px;
  transition: all 0.4s ease;
}
form .btn:hover .btn-layer {
  left: 0;
}
form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.card {
  padding: 20px;
}

.preview1 {
  width: 100%;
  height: 250px;
}

.login_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login_section {
  background-image: url("../images/loginbg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-container {
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.error-divider {
  width: 3px;
  height: 3em;
  background-color: #fff;
  display: inline-block;
  margin: 0 20px;
}
@media (max-width: 768px) {
  .error-divider {
    display: none;
  }
}

@media (max-width: 768px) {
  .error-container {
    text-align: center;
    color: #fff;
    display: block;
  }
}

#bannerSection {
  padding-bottom: 50px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
}

input {
  caret-color: var(--text-color) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}
.input-group-text {
  cursor: pointer !important;
  background-color: rgba(254, 188, 10, 0.78) !important;
}

.card_bg {
  background-color: var(--card-bg);
  border-radius: 10px;
}

table td {
  color: var(--text-color);
}
.nav-pills .nav-link {
  background: 0 0;
  border-radius: 0px !important;
  border: 1px solid #cdcdcd !important;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  color: #000;
  font-size: 14px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background: #613ab1 !important;
}

.form-check-input:checked {
  background-color: rgba(254, 188, 10, 0.78);
  border-color: rgba(254, 188, 10, 0.78);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: var(--text-color) !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: var(--text-color) !important;
}

.nav-button {
  border: 1px solid var(--text-color);
  color: white;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: unset;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.carousel_symbols {
  color: var(--text-color);
  font-size: 25px;
}

.navbar .dropdown-menu {
  border: none;
  background-color: var(--card-bg);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.4);
  color: #000 !important;
  position: relative;
}

.dropdown-item {
  color: var(--text-color);
  margin-bottom: 3px;
}

table {
  vertical-align: middle !important;
}

input[type="number"] {
  background-color: unset;
  color: var(--text-color);
}
input:focus-visible {
  border-color: var(--focus-border-color);
  outline: none;
}

.form-control[type="file"] {
  color: var(--text-color);
}

.theme_toggle {
  position: absolute;
  right: 0px;
  top: 0px;
}

.profile_heading {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
  font-style: normal;
}
/* 
.dropdown-menu {
  width: 230px;
} */

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111111de;
  width: 47px;
  height: 23px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 19px;
  height: 19px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.variants {
  display: flex;
  justify-content: center;
  align-items: center;
}

.variants > div {
  margin-right: 5px;
}

.variants > div:last-of-type {
  margin-right: 0;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  font-weight: 500;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-style: solid;
  border-radius: 10px;
  font-size: 14px;
  color: #000000;
  /* background-color: hsl(0, 0%, 100%); */
  /* padding: 10px 20px; */
  padding: 5px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > label:hover {
  border-color: #d9d9d9;
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204, 86%, 53%);
  border-color: var(--text-color);
  background-color: #d9d9d9;
}

.file--upload > label:hover {
  opacity: 0.8;
}

input[type="text"]:disabled {
  background-color: #dededc !important;
  color: #000 !important;
  font-size: 14px !important;
}
input[type="email"]:disabled {
  background-color: #dededc !important;
  color: #000 !important;
  font-size: 14px !important;
}
.profile_section h4 {
  /* color: #181b36; */
  font-size: 20px;
  font-weight: 600;
}

.profile_section p {
  color: #57626c;
  font-size: 14px;
}
hr {
  width: 100%;
  /* height: 10px; */
  border: 2px solid gray;
}

.form-label p {
  color: #57626c;
  font-size: 14px;
}

.settings_heading {
  color: var(--settings-heading);
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
  color: var(--text-color) !important;
}

.copy_button {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  border-radius: 50%;
  background-color: #613ab1;
  color: #fff;
  border: 1px solid #613ab1;
}

.alert-show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.alert-hide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: none;
}

.alert {
  word-break: break-word;
}

.alert-show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  animation: bounceInLeft 0.5s ease-in-out;
}

.alert-hide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  animation: bounceOutLeft 0.5s ease-out;
  display: none;
}

.alert {
  word-break: break-word;
}

/* Optional: Uncomment and customize if you need a success alert with a green border */
/* .alert-success {
  border-left: 4px solid green !important;
} */

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounceOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(10px);
  }
  60% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

/* .btn-close:focus {
  border: none !important;
} */

.btn-close {
  border: none;
  outline: none;
  box-shadow: none;
}

.btn-close:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-close:focus-visible {
  outline: 2px solid blue;
  box-shadow: none;
}

/* Style for the disabled tab */
.nav-link.disabled {
  background-color: gainsboro; /* Light grey or another color to indicate disabled */
  color: #a0a0a0; /* Grey text color */
  pointer-events: none; /* Disable mouse interactions */
  cursor: not-allowed !important; /* Show a 'not-allowed' cursor */
  opacity: 0.6; /* Make it less opaque to indicate disabled */
}

/* Optional: Style for the active disabled tab */
.nav-link.disabled.active {
  background-color: #e0e0e0; /* Slightly different color if you want */
  color: #b0b0b0; /* Slightly different text color if you want */
}

.alert-success {
  border-left: 7px solid#0f5132 !important;
}
